<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :editable="editable"
            label="부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="griddept"
          title="부서별 사업장 권한"
          tableId="griddept"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :isExcelDown="false"
          :editable="editable"
          :columns="griddept.columns"
          :data="griddept.data"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="저장" icon="save" @btnClicked="saveDept" />
              <c-btn label="검색" icon="search" @btnClicked="getColumns" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="griduser"
          title="사용자별 사업장 권한"
          tableId="griduser"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :isExcelDown="false"
          :editable="editable"
          :columns="griduser.columns"
          :data="griduser.data"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="저장" icon="save" @btnClicked="saveUser" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'user-group-by-user',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        deptCd: '',
        retireFlag: 'N',
      },
      griddept: {
        columns: [],
        data: [],
      },
      griduser: {
        columns: [],
        data: [],
      },
      editable: true,
      userlistUrl: '',
      deptlistUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.userlistUrl = selectConfig.sys.auth.plant.user.list.url;
      this.deptlistUrl = selectConfig.sys.auth.plant.dept.list.url;
      this.saveUrl = transactionConfig.sys.auth.plant.insert.url;
      this.getColumns();
    },
    getColumns() {
      this.$comm.getComboItems('PLANT_CD').then(_result => {
        let _combos = _result.filter( x => {
          return x.code != 'ENTERPRISE'
        });
        this.griddept.columns = [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'left',
            sortable: true,
          },
          {
            name: 'plantCds',
            field: 'plantCds',
            label: '사업장 권한',
            align: 'center',
            type: 'multiSelect',
            itemText: 'codeName',
            itemValue: 'code',
            style: 'width:65%',
            isArray: false,
            comboItems: _combos,
            sortable: false,
          },
        ];
        this.griduser.columns = [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '사용자명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'plantCds',
            field: 'plantCds',
            label: '사업장 권한',
            align: 'center',
            type: 'multiSelect',
            itemText: 'codeName',
            itemValue: 'code',
            style: 'width:55%',
            isArray: false,
            comboItems: _combos,
            sortable: false,
          },
        ];
        this.getDeptList();
        this.getUserList();
      });
    },
    getDeptList() {
      this.$http.url = this.deptlistUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.griddept.data = _result.data;
      },);
    },
    getUserList() {
      this.$http.url = this.userlistUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (this.$store.getters.user.userId === 'U000000001') {
          this.griduser.data = _result.data;
        } else {
          this.griduser.data = this.$_.filter(_result.data, item => {return item['authId'] !== 'U000000001'});
        }
      },);
    },
    saveDept() {
      let saveData = this.griddept.data.filter( x => {
        return x.editFlag == 'U'
      });
      this.$http.url = this.saveUrl;
      this.$http.type = 'POST';
      this.$http.param = saveData;
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getDeptList();
      });
    },
    saveUser() {
      let saveData = this.griduser.data.filter( x => {
        return x.editFlag == 'U'
      });
      this.$http.url = this.saveUrl;
      this.$http.type = 'POST';
      this.$http.param = saveData;
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getUserList();
      });
    },
  }
};
</script>